import "./tailwind.css"
import type { LinksFunction, MetaFunction } from "@remix-run/node"
import {
  json,
  Links,
  Meta,
  type MetaDescriptor,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react"
import ErrorPage from "./components/ErrorPage"
import { sentryError } from "./utils/sentry"
export const meta: MetaFunction = (): Array<MetaDescriptor> => [
  { charSet: "utf-8" },
  { name: "contentType", content: "text/html; charset=utf-8" },
  { title: "Auth Ligonier" },
  { name: "viewport", content: "width=device-width,initial-scale=1" },
  { name: "description", content: "Ligonier's Authenticated App" },
]

export const links: LinksFunction = () => [
  {
    rel: "icon",
    href: "/favicon-light.png",
    sizes: "32x32",
    media: "(prefers-color-scheme: light)",
  },
  {
    rel: "icon",
    href: "/favicon-light.png",
    sizes: "32x32",
    media: "(prefers-color-scheme: dark)",
  },
]

export function ErrorBoundary() {
  const error = useRouteError()
  const isErrorInstance = error instanceof Error
  sentryError(isErrorInstance ? error : new Error(String(error)), {
    title: isErrorInstance ? error.name : "Unknown Error",
    section: "Root Error Boundary",
    message: isErrorInstance ? error.message : String(error),
  })
  return (
    <html lang="en" className="h-full">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <ErrorPage />
      </body>
    </html>
  )
}

export const loader = () => {
  return json({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_PROJECT: process.env.SENTRY_PROJECT,
      SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
    },
  })
}

export default function App() {
  const { ENV } = useLoaderData<typeof loader>()

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}
