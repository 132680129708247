import { Link } from "@remix-run/react"
import Page from "./Page"

export default function ErrorPage() {
  return (
    <Page class="h-full">
      <section className="flex w-full flex-col items-center justify-center bg-cream px-5 h-full">
        <div className="mb-12 text-center">
          <h1 className="mb-6 font-mercury text-4xl text-dark-copy md:text-5xl">
            We Apologize.
          </h1>
          <p className="text-lg text-body-copy md:text-xl">
            An error occurred. Please try again or contact support using the{" "}
            <Link className="text-primary-500" to="mailto:service@ligonier.org">
              email{" "}
            </Link>
            or calling{" "}
            <Link className="text-primary-500" to="tel:800-435-4343">
              800-435-4343
            </Link>
            .
          </p>
        </div>
      </section>
    </Page>
  )
}
