import classNames from "classnames"
import React from "react"

export const Page = ({
  children,
  class: className,
}: {
  children: React.ReactNode
  class?: string
}) => {
  return (
    <main
      className={classNames(
        "flex flex-col items-center justify-center overflow-y-auto",
        className,
      )}
    >
      {children}
    </main>
  )
}

export default Page
