import * as Sentry from "@sentry/remix"

type SentryCaptureException = {
  section: string
  message: string
  title: string
}

export function sentryError(
  exception: any,
  { section, message, title }: SentryCaptureException,
) {
  console.error("sentryCaptureException", { title, section, message })
  Sentry.captureException(exception, {
    tags: {
      section,
      description: message,
    },
  })
}
